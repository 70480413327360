import { Typography } from "@material-ui/core";
import React from "react";

const ChatFlow = () => {
  return (
    <div>
      <Typography
        style={{
          flexGrow: 1,
          fontWeight: "bold",
          WebkitJustifyContent: "right",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        align="center"
      >
        Mobile ChatFlow Loading...
      </Typography>
    </div>
  );
};

export default ChatFlow;
